import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Router } from '@reach/router';

import { GlosarioLayout } from '../layouts';
import { PlanRouter } from '../components/router';
import { api } from '../services';

const GlosarioPage = ({ letter }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const { fetchGlosarioData } = api();

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        const response = await fetchGlosarioData(currentLang);

        if (!ignore) {
          setData(response);
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang, letter, fetchGlosarioData]);

  return (
    <Router>
      <PlanRouter
        path="/glosario/:letter"
        component={GlosarioLayout}
        data={data}
        loading={loading}
        error={error}
        letter={letter}
      />
    </Router>
  );
};

export default GlosarioPage;
